/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-undef */
import { configureStore } from "@reduxjs/toolkit";
// import { ThunkAction } from "redux-thunk";

import { combineReducers } from "redux";
import authReducer from "./features/auth/auth";
import filterReducer from "./features/filters/filters";
import globalReducer from "./features/global/global";
import eventReducer from "./features/event/event";
import promoReducer from "./features/promo/promo";
import dashboardReduer from "./features/dashboard/dashboard";
import reportingReducer from "./features/reporting/reporting";

const rootReducer = combineReducers({
	auth: authReducer,
	global: globalReducer,
	filters: filterReducer,
	event: eventReducer,
	promo: promoReducer,
	dashboard: dashboardReduer,
	reports: reportingReducer,
});

const store = configureStore({
	reducer: rootReducer,
});

// export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof rootReducer>;
// export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export default store;
