import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import uuid from "react-uuid";
import { useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { AppBar, styled, Avatar } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { signOut } from "../../../store/features/auth/auth";
import {
	getNotifications,
	removeLoader,
	requestCompleteNoLoader,
	addNewNotification,
	toastError,
	getUserHierarchy,
	getGlobalConfig,
	toggleLengthyOpLoader,
	setNewSseConnection,
	getGlobalConfigOptions,
	updateNotificationStatus,
	setUnreadNotificationsCount,
} from "../../../store/features/global/global";
import { setSimulatedPromoId } from "../../../store/features/promo/promo";
import { setRetainFilters } from "../../../store/features/filters/filters";
import { MODEL_API, screenNames } from "../../../constants/Constants";
import { protectedRoutes } from "../../../containers/common/Routes";
import Breadcrumbs from "../header/Breadcrumbs";
import { downloadReports } from "../../../store/features/reporting/reporting";
import "./Header.scss";

const { REACT_APP_API_BASE_URL } = process.env;

const StyledAppBar = styled(AppBar)((theme) => ({
	padding: "0px",
	background: "#fff",
	boxShadow: "0px 1px 4px #00000029",
	height: "72px",
	position: "sticky",
}));

const getGuid = () => {
	const id = uuid(); // generate unique UUID
	sessionStorage.setItem("UNIQ_SSE_KEY", id);
	return id;
};

const Header = (props) => {
	const location = useLocation();

	const [notifications, setNotifications] = useState([]);
	const unreadCountRef = useRef(0);

	// Adding event listener for SSE - Lock/Unlock & Override/Finalize
	useEffect(() => {
		const uniqueId = getGuid();
		const token = localStorage.getItem("token");
		const sseListener = new EventSource(
			REACT_APP_API_BASE_URL +
				"/sse-output?guid=" +
				uniqueId +
				"&token=" +
				token,
			{
				authorizationHeader: `Bearer ${token}`,
			}
		);
		establishSseConnection(sseListener);

		return () => {
			sseListener.close();
			sessionStorage.removeItem("UNIQ_SSE_KEY");
		};
	}, []);

	useEffect(() => {
		if (props.newSseConnection) {
			const uniqueId = getGuid();
			const token = localStorage.getItem("token");
			const sseListener = new EventSource(
				REACT_APP_API_BASE_URL +
					"/sse-output?guid=" +
					uniqueId +
					"&token=" +
					token,
				{
					// withCredentials: true,
				}
			);
			establishSseConnection(sseListener);
			props.setNewSseConnection(false);
		}
	}, [props.newSseConnection]);

	const establishSseConnection = (sseListener) => {
		const maxReconnectTries = 100;
		let reconnectAttempts = 0;
		sseListener.onmessage = (e) => {
			// Check event type and parse accordingly
			const parsedResponse = JSON.parse(e.data);
			const eventsComplete = [];
			const newNotifications = [];
			!_.isEmpty(parsedResponse) &&
				!_.isEmpty(parsedResponse.messages) &&
				parsedResponse.messages.map((eventResponse) => {
					if (
						eventResponse.status === 200 &&
						(eventResponse.action === "resimulate" ||
							eventResponse.action === "optimise")
					) {
						// Resimulate OR Optimization complete
						props.setSimulatedPromoId(eventResponse.promo_id);
						props.toggleLengthyOpLoader(false);
					} else if (
						(eventResponse.action === "resimulate" ||
							eventResponse.action === "optimise") &&
						eventResponse.status !== 200
					) {
						props.toggleLengthyOpLoader(false);
						props.toastError(eventResponse.message);
					} else if (
						eventResponse.action === "bulk_resimulate" &&
						eventResponse.status === 200
					) {
						const unreadNotifications = unreadCountRef.current + 1;
						const editedEvent = _.cloneDeep(eventResponse);
						editedEvent.read_at = null;
						editedEvent.read_by = null;
						editedEvent.is_broadcast_message = 0;
						editedEvent.is_sse_notification = true;
						unreadCountRef.current = unreadNotifications;
						newNotifications.push(editedEvent);
						props.requestCompleteNoLoader(eventResponse.message);
					} else if (
						eventResponse.action === "bulk_resimulate" &&
						eventResponse.status !== 200
					) {
						props.toastError(eventResponse.message);
					} else if (
						eventResponse.action === "download" ||
						eventResponse.status === 200
					) {
						switch (eventResponse.module) {
							case "REPORT":
								const tempunreadNotifications =
									unreadCountRef.current + 1;
								const downloadEvent =
									_.cloneDeep(eventResponse);
								downloadEvent.read_by = null;
								downloadEvent.is_broadcast_message = 0;
								unreadCountRef.current =
									tempunreadNotifications;
								newNotifications.push(downloadEvent);
								break;
							case "EVENT_AUTO_RESIMULATION":
								const unreadNotifications =
									unreadCountRef.current + 1;
								const editedEvent = _.cloneDeep(eventResponse);
								editedEvent.read_by = null;
								editedEvent.is_broadcast_message = 0;
								editedEvent.is_sse_notification = true;
								unreadCountRef.current = unreadNotifications;
								newNotifications.push(editedEvent);
								break;
							case "PROMO_AUTO_RESIMULATION":
								const unreadNotificationsPromo =
									unreadCountRef.current + 1;
								const editedEventPromo =
									_.cloneDeep(eventResponse);
								editedEventPromo.read_by = null;
								editedEventPromo.is_broadcast_message = 0;
								editedEventPromo.is_sse_notification = true;
								unreadCountRef.current =
									unreadNotificationsPromo;
								newNotifications.push(editedEventPromo);
								break;
							default:
								console.log(eventResponse);
								break;
						}
						// Dispatch request complete event to show the success message
						props.requestCompleteNoLoader(eventResponse.message);
					} else {
						console.log("Error", eventResponse);
						props.removeLoader();
						props.toastError(eventResponse.message);
					}
				});
			if (newNotifications.length > 0) {
				props.addNewNotification(newNotifications);
			}
			// props.sseCompleteEvents(eventsComplete);
		};
		sseListener.onerror = (e) => {
			console.log(e);
			if (reconnectAttempts > maxReconnectTries) {
				sseListener.close();
				sessionStorage.removeItem("UNIQ_SSE_KEY");
				props.toastError(
					"There is an issue establishing SSE connection. Please contact Pricesmart team for details."
				);
			} else {
				console.log("Reconnecting....");
				reconnectAttempts++;
			}
		};
	};

	let userName = "User";
	if (localStorage.getItem("user")) {
		userName = localStorage.getItem("user");
		if (userName.includes(",")) {
			let userNameArray = userName.split(",");
			userName =
				userNameArray.length > 1 ? userNameArray[1] : userNameArray[0];
		} else if (userName.includes(" ")) {
			userName = userName.split(" ")?.[0];
		}
	}

	const [showNotifications, toggleNotifications] = useState(false);

	const handleNotificationIconClick = async () => {
		if (!showNotifications) {
			const res = await props.getNotifications(false);

			toggleNotifications(!showNotifications);
		} else {
			toggleNotifications(!showNotifications);
		}
	};

	useEffect(() => {
		// Get global screen config
		// Get current screen name
		const pathName = location?.pathname;
		const currentRoute = protectedRoutes?.find((r) => r.path === pathName);
		const currentScreen = screenNames[currentRoute?.key];

		props.getGlobalConfig({ screen: currentScreen });

		// Get all notifications for user
		props.getNotifications(false);

		// Get user permissions (hierarchy and actions)
		// props.getUserHierarchy("all");

		document.addEventListener("click", (e) => {
			if (
				!e.target.closest(".notification-icon") &&
				!e.target.closest(".notification-drawer")
			) {
				toggleNotifications(false);
			}
		});

		const globalConfigOptionsPayload = {
			id: MODEL_API.GLOBAL_CONFIG_OPTIONS.MODEL_ID,
			parameters: {
				...MODEL_API.GLOBAL_CONFIG_OPTIONS.parameters,
			},
		};
		props.getGlobalConfigOptions(globalConfigOptionsPayload);
	}, []);

	useEffect(async () => {
		if (props.notifications && !_.isEmpty(props.notifications)) {
			setNotifications(() => _.cloneDeep(props.notifications));
			let unreadCount = 0;
			let unreadEventNotifation = [];

			props.notifications.forEach((notification) => {
				//add unread notification count unread = (is_broadcast_message === 0 && isNull(read_at))
				if (
					notification?.is_broadcast_message === 0 &&
					_.isNull(notification.read_at)
				) {
					// count all unread notications
					unreadCount++;
				}
				if (
					(notification.module == "EVENT_AUTO_RESIMULATION" ||
						notification.module === "bulk_resimulate" ||
						notification.module == "PROMO_AUTO_RESIMULATION") &&
					_.isNull(notification.read_by) &&
					!notification.is_sse_notification
				) {
					// event auto resimulation unread notifications
					unreadEventNotifation.push(notification.uuid);
				}
			});

			const reqObj = {
				uuid: unreadEventNotifation,
			};

			if (unreadEventNotifation.length > 0) {
				await props.updateNotificationStatus(reqObj);
				const unreadEventNotifationcount = unreadEventNotifation.length;
				// subtract event auto resimulation unread notifications count from all unread notification count
				unreadCount = unreadCount - unreadEventNotifationcount;
			}

			unreadCountRef.current = unreadCount;
		}
	}, [props.notifications]);

	const handleNotificationClick = async (notification) => {
		toggleNotifications(false);
		if (notification.module === "EVENTS") {
			console.log("EVENT");
		} else if (notification.module === "PROMO") {
			console.log("PROMO");
		} else if (notification.module === "REPORT") {
			console.log("notification", notification);
			const unreadCount = unreadCountRef.current - 1;
			unreadCountRef.current = unreadCount;

			if (_.isNull(notification.read_by)) {
				const reqObj = {
					uuid: [notification.uuid],
				};

				await props.updateNotificationStatus(reqObj);
			}

			if (notification.url) {
				window.open(notification.url, "_self");
			}
		}
	};

	const stringAvatar = (name) => {
		// const nameArray = name.split(" ");
		// let userName = "User";
		// if (localStorage.getItem("user")) {
		//     userName = localStorage.getItem("user");
		//     if (userName.includes(",")) {
		//         let userNameArray = userName.split(",");
		//         userName =
		//             userNameArray.length > 1 ? userNameArray[1] : userNameArray[0];
		//     } else if (userName.includes(" ")) {
		//         userName = userName.split(" ")?.[0];
		//     }
		// }
		return {
			sx: {
				bgcolor: "#033162",
				width: "48px",
				height: "48px",
				boxShadow: "0px 0px 6px #00000029",
				textAlign: "left",
				fontSize: "16px",
				lineHeight: "25px",
				fontWeight: "600",
				letterSpacing: "0px",
				color: "#FFFFFF",
			},
			children: `${name.trim().charAt(0)}`,
		};
	};

	return (
		<>
			<StyledAppBar>
				<div className="navbar-brand">
					<h1 className="app-name">{props.title}</h1>
					<ul className="navbar-nav">
						<li
							title="Notifications"
							className="nav-item"
							id="notification-nav-item"
						>
							<NotificationsIcon
								className="notification-icon"
								sx={{
									width: "23px",
									height: "27px",
									cursor: "pointer",
									color: "#C8CED0",
									marginTop: "8px",
								}}
								onClick={() => handleNotificationIconClick()}
							/>
							{unreadCountRef.current > 0 && (
								<span className="notification-count">
									{unreadCountRef.current}
								</span>
							)}
						</li>

						<li className="nav-item">
							<Avatar {...stringAvatar(userName)} />
						</li>
						<li className="nav-item">Hi {userName} </li>
					</ul>
				</div>
				{showNotifications && (
					<div className="notification-drawer">
						<p className="notifications-title">Notifications</p>
						{notifications && !_.isEmpty(notifications) && (
							<ul className="notification-ul">
								{notifications.map((notification) => {
									return (
										<li
											className="notification-li"
											key={notification.uuid}
											onClick={() =>
												handleNotificationClick(
													notification
												)
											}
										>
											<p className="notification-type">
												{notification?.is_broadcast_message ===
													0 &&
													_.isNull(
														notification.read_at
													) &&
													notification.module ===
														"REPORT" && (
														<span className="notification-unread"></span>
													)}
												{
													props.elementLabels[
														notification.module
													]
												}
											</p>
											<p className="notification-message">
												{notification.message}
											</p>
											<p className="notification-time">
												{notification.created_at}
											</p>
										</li>
									);
								})}
							</ul>
						)}
						{(!notifications || _.isEmpty(notifications)) && (
							<p className="no-notifications">
								No new notifications
							</p>
						)}
					</div>
				)}
			</StyledAppBar>
			<Breadcrumbs />
		</>
	);
};

const mapStateToProps = (store) => {
	return {
		notifications: store.global.notifications,
		elementLabels: store.global.elementLabels,
		newSseConnection: store.global.newSseConnection,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		signOut: () => {
			dispatch(signOut());
		},
		getNotifications: (data) => {
			dispatch(getNotifications(data));
		},
		requestCompleteNoLoader: (message) =>
			dispatch(requestCompleteNoLoader(message)),
		removeLoader: () => dispatch(removeLoader()),
		setRetainFilters: (screenName) =>
			dispatch(setRetainFilters(screenName)),
		addNewNotification: (notificationList) =>
			dispatch(addNewNotification(notificationList)),
		toastError: (data) => dispatch(toastError(data)),
		getUserHierarchy: (data) => dispatch(getUserHierarchy(data)),
		getGlobalConfig: (data) => dispatch(getGlobalConfig(data)),
		toggleLengthyOpLoader: (data) => dispatch(toggleLengthyOpLoader(data)),
		setSimulatedPromoId: (data) => dispatch(setSimulatedPromoId(data)),
		setNewSseConnection: (data) => dispatch(setNewSseConnection(data)),
		downloadReports: (data) => dispatch(downloadReports(data)),
		updateNotificationStatus: (data) =>
			dispatch(updateNotificationStatus(data)),
		getGlobalConfigOptions: (data) =>
			dispatch(getGlobalConfigOptions(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
